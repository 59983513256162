import React from 'react'
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MailTo from "../components/mail-to"
// import { Helmet } from 'react-helmet'

const Privacy = () => {
  return(
    <Layout>
      <SEO 
        title="Privacy Policy" 
        description="Read about how we keep our customers data safe and secure." 
        url="https://www.billsby.com/privacy"
      />

      <div className="default-template ">
        <div className="section-tab">
          <div className="container">
            <ul className="tab-wrapper">
              <li className="tab-item"><Link to="/terms">Terms and conditions</Link></li>
              <li className="is-active tab-item" to="/privacy"><Link>Privacy policy</Link></li>
              <li className="tab-item"><Link to="/privacy/dpa">EU Data Processing Addendum</Link></li>
              <li className="tab-item"><Link to="/privacy/cookie-policy">Cookie policy</Link></li>
              <li className="tab-item"><Link to="/privacy/sub-processors">Sub-processors</Link></li>
              <li className="tab-item"><Link to="/terms/acceptable-use">Acceptable use policy</Link></li>
            </ul>

            <p className="date">EFFECTIVE 1ST AUGUST 2019</p>
            <h1 className="section-heading">Privacy policy</h1>
          </div>
        </div>
        <div className="container default-content">
          <h4>Our privacy policy</h4>
          <p>We at Billsby (“company”, “we”, “us”, “our”) know that our users (“you,” “your”) care about how your personal information is used and shared, and we take your privacy seriously. Please read the following to learn more about our privacy policy.</p>

          <p>By visiting or using the www.billsby.com website (the <strong>“website“</strong>) or any other features, content, or applications offered from time to time by Company in connection therewith (collectively, the “<strong>services</strong>“) in any manner, you acknowledge that you accept the practices and policies outlined in this privacy policy, and you hereby consent that we will collect, use, and share your information in the following ways.</p>

          <p>If you have any questions or concerns please contact {MailTo({classes: ''})}.</p>

          <h4>What does this privacy policy cover?</h4>
          <p>This privacy policy covers our treatment of personally identifiable information (“<strong>personal information</strong>“) that we gather when you are accessing our <strong>services</strong>. This policy does not apply to the practices of companies that we do not own or control, or to individuals that we do not employ or manage.</p>

          <p>We do not knowingly collect or solicit <strong>personal information</strong> from anyone under the age of 13 or knowingly allow such persons to register for our <strong>services</strong>. If you are under 13, please do not attempt to register for the <strong>services</strong> or send any information about yourself to us, including your name, address, telephone number, or email address. No one under age 13 may provide any <strong>personal information</strong> to us or on the <strong>services</strong>. In the event that we learn that we have collected <strong>personal information</strong> from a child under age 13 without verification of parental consent, we will delete that information as quickly as possible. If you believe that we might have any information from or about a child under 13, please contact us at {MailTo({classes: ''})}.</p>

          <p>We gather various types of <strong>personal information</strong> from our users, as explained more fully below. We may use this <strong>personal information</strong> to personalize and improve our services, to contact users, to fulfill your requests for certain products and services, to analyze how users utilize the <strong>services</strong>, and as otherwise set forth in this <strong>privacy policy</strong>. We may share certain types of <strong>personal information</strong> with third parties, as described below.</p>

          <h4>What information does Billsby collect?</h4>

          <p>Information you provide to us</p>
          <p>We receive and store any information you knowingly provide to us. For example, we collect <strong>personal information</strong>, including but not limited to, your name, title, company, email address, phone number, address, location and browser information. You can choose not to provide us with certain information, but then you may not be able to register with us or to take advantage of some of our features. We may anonymize your <strong>personal information</strong> so that you cannot be individually identified, and provide that information to our partners.</p>
          <p>If you have provided us with a means of contacting you, we may use such means to communicate with you. For example, we may send you promotional offers on behalf of other businesses, or communicate with you about your use of the <strong>services</strong>. Also, we may receive a confirmation when you open a message from us. This confirmation helps us make our communications with you more interesting and improve our services. If you do not want to receive communications from us, please indicate your preference by sending an email to {MailTo({classes: ''})}. Please note that if you do not want to receive legal notices from us, those legal notices will still govern your use of the <strong>services</strong>, and you are responsible for reviewing such legal notices for changes.</p>
          <p>Information collected automatically</p>
          <p>Whenever you interact with our <strong>services</strong>, we automatically receive and record information on our server logs from your browser including your IP address, “cookie” information, and the page you requested. “Cookies” are identifiers we transfer to your computer or mobile device that allow us to recognize your browser or mobile device and tell us how and when pages and features in our <strong>services</strong> are visited and by how many people. You may be able to change the preferences on your browser or mobile device to prevent or limit your computer or device’s acceptance of cookies, but this may prevent you from taking advantage of some of our features. If you click on a link to a third party website, such third party may also transmit cookies to you. This <strong>privacy policy</strong> does not cover the use of cookies by any third parties.</p>
          <p>When we collect usage information (such as the numbers and frequency of visitors to the <strong>website</strong>), we only use this data in aggregate form, and not in a manner that would identify you personally. For example, this aggregate data tells us how often users use parts of the <strong>services</strong>, so that we can make the services appealing to as many users as possible. We may also provide this aggregate information to our partners; our partners may use such information to understand how often and in what ways people use our <strong>services</strong>, so that they, too, can provide you with an optimal experience. We never disclose aggregate information to a partner in a manner that would identify you personally.</p>

          <h4>Will Billsby share any of the personal information it receives?</h4>

          <p>We neither rent nor sell your <strong>personal information</strong> in personally identifiable form to anyone. However, we do share your <strong>personal informatio</strong> with third parties as described above and in this section:</p>
          <p>Affiliated businesses and third party websites we do not control</p>
          <p>In certain situations, businesses or third party websites we’re affiliated with may sell items or provide services to you through the <strong>services</strong> (either alone or jointly with us). You can recognize when an affiliated business is associated with such a transaction or service, and we will share your <strong>personal information</strong> with that affiliated business only to the extent that it is related to such transaction or service. We have no control over the policies and practices of third party websites or businesses as to privacy or anything else, so if you choose to take part in any transaction or service relating to an affiliated website or business, please review all such business’ or websites’ policies.</p>
          <p>Agents</p>
          <p>We employ other companies and people to perform tasks on our behalf and need to share your information with them to provide products or services to you. Unless we tell you differently, our agents do not have any right to use the personal information we share with them beyond what is necessary to assist us.</p>
          <p>Business transfers</p>
          <p>We may choose to buy or sell assets. In these types of transactions, customer information is typically one of the business assets that would be transferred. Also, if we (or our assets) are acquired, or if we go out of business, enter bankruptcy, or go through some other change of control, <strong>personal information</strong> would be one of the assets transferred to or acquired by a third party.</p>
          <p>Protection of company and others</p>
          <p>We reserve the right to access, read, preserve, and disclose any information that we reasonably believe is necessary to comply with law or court order; enforce or apply our conditions of use and other agreements; or protect the rights, property, or safety of <strong>company</strong>, our employees, our users, or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction.</p>
          <p>With your consent</p>
          <p>Except as set forth above, you will be notified when your <strong>personal information</strong> may be shared with third parties in personally identifiable form, and will be able to prevent the sharing of this information.</p>

          <h4>Is personal information about me secure?</h4>
          <p>We endeavor to protect the privacy of your <strong>personal information</strong> we hold in our records, but we cannot guarantee complete security. Unauthorized entry or use, hardware or software failure, and other factors, may compromise the security of user information at any time.</p>
          <p>The <strong>services</strong> may contain links to other sites. We are not responsible for the privacy policies and/or practices on other sites. When following a link to another site you should read that site’s privacy policy.</p>

          <h4>What personal information can I access?</h4>
          <p>Our services give you access to a broad range of information (including <strong>personal information</strong>) about your account and your interactions with the <strong>service</strong> for the purpose of viewing and, in certain cases, updating or deleting that information. The information you can view, update, and delete may change as the <strong>services</strong> change. If you have any questions about viewing or updating information we have on file about you, please contact us at {MailTo({classes: ''})}.</p>

          <h4>What choices do I have</h4>
          <p>You can always opt not to disclose information to use, but keep in mind some information may be needed to register with us or to take advantage of some of our special features.</p>

          <p>You may request deletion of your information by emailing us at {MailTo({classes: ''})}. Please note that some information may remain in our records, for example in our archives, after your request of deletion of such information. We may use any aggregated data derived from or incorporating your <strong>personal information</strong> after you update or delete it, but not in a manner that would identify you personally. Please also note that comments you post publicly on our <strong>website</strong>, such as comments on our blog posts, will remain visible to the public.</p>

          <h4>Changes to this privacy policy</h4>
          <p>We may amend this <strong>privacy policy</strong> from time to time. Use of information we collect now is subject to the <strong>privacy policy</strong> in effect at the time such information is used. If we make changes in the way we use <strong>personal information</strong>, we will notify you by posting an announcement on our <strong>website</strong> or sending you a message. You are bound by any changes to the <strong>privacy policy</strong> when you use the <strong>services</strong> after such changes have been first posted.</p>

          <h4>EU-US Privacy Shield Framework and Swiss-US Privacy Shield Framework</h4>
          <p><strong>Billsby</strong> complies with the EU-U.S. Privacy Shield Framework and Swiss-U.S. Privacy Shield Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information transferred from the European Economic Area and Switzerland to the United States.</p>
          <p><strong>Billsby</strong> is subject to the investigatory and enforcement powers of the Federal Trade Commission (FTC). As explained above, we sometimes provide <strong>personal information</strong> to third parties to perform services on our behalf. If we transfer <strong>personal information</strong> received under the Privacy Shield to a third party, the third party’s access, use, and disclosure of the Personal Information must also be in compliance with our Privacy Shield obligations, and we will remain liable under the Privacy Shield for any failure to do so by the third party unless we prove we are not responsible for the event giving rise to the damage. If there is any conflict between the terms in this privacy policy and the Privacy Shield Principles, the Privacy Shield Principles shall govern. To learn more about the Privacy Shield program, and to view our certification, please visit <a href="https://www.privacyshield.gov/">https://www.privacyshield.gov/.</a></p>

          <h4>General Data Protection Regulation (GDPR)</h4>
          <p>EU residents</p>
          <p>If you are a resident of the European Union (“<strong>EU</strong>“), United Kingdom, Lichtenstein, Norway, or Iceland, you may have additional rights under the EU General Data Protection Regulation (the “GDPR“) with respect to your Personal Data, as outlined below.</p>

          <p>For this section, we use the terms “<strong>personal data</strong>” and “<strong>processing</strong>” as they are defined in the <strong>GDPR</strong>, but “<strong>personal data</strong>” generally means information that can be used to individually identify a person, and “<strong>processing</strong>” generally covers actions that can be performed in connection with data such as collection, use, storage and disclosure. <strong>Company</strong> will be the controller of your <strong>personal data</strong> processed in connection with the <strong>services</strong>. Note that we may also process <strong>personal data</strong> of our customers’ end users or employees in connection with our provision of services to customers, in which case we are the processor of <strong>personal data</strong>. If we are the processor of your <strong>personal data</strong> (i.e., not the controller), please contact the controller party in the first instance to address your rights with respect to such data.</p>

          <p>If there are any conflicts between this section and any other provision of this <strong>privacy policy</strong>, the policy or portion that is more protective of <strong>personal data</strong> shall control to the extent of such conflict. If you have any questions about this section or whether any of the following applies to you, please contact us at {MailTo({classes: ''})}</p>

          <h4>What personal data do we collect from you?</h4>
          <p>We collect <strong>personal data</strong> about you when you provide such information directly to us, when third parties such as our business partners or service providers provide us with <strong>personal data</strong> about you, or when <strong>personal data</strong> about you is automatically collected in connection with your use of our <strong>services</strong>.</p>

          <p>Information we collect directly from you</p>
          <p>We receive personal data directly from you when you provide us with such personal data, including without limitation the following:</p>
          <ul>
            <li>First and last name</li>
            <li>Company name, VAT number</li>
            <li>Email address</li>
            <li>Mailing address</li>
            <li>Telephone number</li>
            <li>Address</li>
            <li>IP Address</li>
            <li>Username</li>
            <li>Browser information</li>
            <li>Credit card and credit card CVV</li>
            <li>Transaction and billing data</li>
          </ul>

          <p>Information we receive from third party sources</p>
          <p>Some third parties such as our business partners and service providers provide us with personal data about you, such as the followin</p>

            <ul>
              <li><strong>Account information for third party services:</strong> If you interact with a third party service when using our <strong>services</strong>, such as if you use a third party service to log-in to our <strong>services</strong> (e.g., Facebook Connect or Twitter OAuth), or if you share content from our <strong>services</strong> through a third party social media service, the third party service will send us information about you, such as information from your public profile, if the third party service and your account settings allow such sharing. The information we receive will depend on the policies and your account settings with the third party service.</li>
            </ul>
          
          <p>Information we automatically collect when you use our services</p>
          <p>Some personal data is automatically collected when you use our services, such as the following:</p>

            <ul>
              <li>IP address</li>
              <li>Device identifiers</li>
              <li>Web browser information</li>
              <li>Page view statistics</li>
              <li>Browsing history</li>
              <li>Usage information</li>
              <li>Transaction information (e.g. transaction amount, date and time such transaction occurred)</li>
              <li>Cookies and other tracking technologies (e.g. web beacons, pixel tags, SDKs, etc.). For more information, please review our <a href="https://billsby.com/privacy/cookies">Cookie Policy</a>.</li>
              <li>Location information (e.g. IP address, zip code)</li>
              <li>Log data (e.g. access times, hardware and software information)</li>
            </ul>
          <p>How do we use your personal data?</p>
          <p>We process <strong>personal data</strong> to operate, improve, understand and personalize our services. For example, we use <strong>personal data</strong> to:</p>
            <ul>
              <li>Create and manage user profiles</li>
              <li>Communicate with you about the <strong>services</strong></li>
              <li>Process orders</li><li>Contact you about <strong>service</strong> announcements, updates or offers</li>
              <li>Provide support and assistance for the <strong>services</strong></li>
              <li>Personalize website content and communications based on your preferences</li>
              <li>Meet contract or legal obligations</li>
              <li>Respond to user inquiries</li>
              <li>Fulfill user requests</li>
              <li>Comply with our legal or contractual obligations</li>
              <li>Resolve disputes</li>
              <li>Protect against or deter fraudulent, illegal or harmful actions</li>
              <li>Enforce our Terms of Service</li>
            </ul>
            <p>We will only process your <strong>personal data</strong> if we have a lawful basis for doing so. Lawful basis for processing include consent, contractual necessity and our “legitimate interests” or the legitimate interest of others, as further described below.</p>
            <p><em>Contractual necessity</em></p>
            <p>We process the following categories of <strong>personal data</strong> as a matter of “contractual necessity”, meaning that we need to process the data to perform under our Terms of Service with you, which enables us to provide you with the Services. When we process data due to contractual necessity, failure to provide such <strong>personal data</strong> will result in your inability to use some or all portions of the Services that require such data.</p>
              <ul>
                <li>First and last name</li>
                <li>Email address</li>
                <li>Mailing address</li>
                <li>IP Address</li>
                <li>Credit card and credit card CVV</li>
                <li>Transaction and Billing Data</li>
              </ul>
            <p><em>Legitimate interest</em></p><p>We process the following categories of <strong>personal data</strong> when we believe it furthers the legitimate interest of us or third parties.</p>
              <ul>
                <li>First and last name</li>
                <li>Email address</li>
                <li>Mailing address</li>
              </ul>
            <p>Examples of these legitimate interests include:</p>
              <ul>
                <li>Operation and improvement of our business, products and services</li>
                <li>Marketing of our products and services</li>
                <li>Provision of customer support</li>
                <li>Protection from fraud or security threats</li>
                <li>Compliance with legal obligations</li>
                <li>Completion of corporate transactions</li>
              </ul>
              <p><em>Consent</em></p><p>In some cases, we process <strong>personal data</strong> based on the consent you expressly grant to us at the time we collect such data. When we process <strong>personal data</strong> based on your consent, it will be expressly indicated to you at the point and time of collection.</p>
              <p><em>Other processing grounds</em></p>
              <p>From time to time we may also need to process <strong>personal data</strong> to comply with a legal obligation, if it is necessary to protect the vital interests of you or other data subjects, or if it is necessary for a task carried out in the public interest.</p>
              <p>How and with whom do we share your data?</p>
              <p>We share <strong>personal data</strong> with vendors, third party service providers and agents who work on our behalf and provide us with services related to the purposes described in this <strong>privacy policy</strong> or our <strong>terms of service.</strong> These parties include:</p>
                <ul>
                  <li>Payment processors</li>
                  <li>Fraud prevention service providers</li>
                  <li>Ad networks</li>
                  <li>Analytics service providers</li>
                  <li>Staff augmentation and contract personnel</li>
                  <li>Hosting service providers</li>
                  <li>Co-location service providers</li>
                  <li>Telecommunications service providers</li>
                </ul>
              <p>We also share <strong>personal data </strong>when necessary to complete a transaction initiated or authorized by you or provide you with a product or service you have requested. In addition to those set forth above, these parties also include:</p>
                <ul>
                  <li>Other users (where you post information publicly or as otherwise necessary to effect a transaction initiated or authorized by you through the <strong>services)</strong></li>
                  <li>Social media services (if you interact with them through your use of the <strong>services)</strong></li>
                  <li>Third party business partners who you access through the <strong>services</strong></li>
                  <li>Other authorized parties</li>
                </ul>
              <p>We also share information with third parties when you have given us consent to do so (as indicated at the point such information is collected).</p><p>We also share <strong>personal data</strong> when we believe it is necessary to:</p>
                <ul>
                  <li>Comply with applicable law or respond to valid legal process, including from law enforcement or other government agencies</li>
                  <li>Protect us, our business or our users, for example to enforce our terms of service, prevent spam or other unwanted communications and investigate or protect against fraud</li>
                  <li>Maintain the security of our products and services</li>
                </ul>
              <p>Furthermore, if we choose to buy or sell assets, user information is typically one of the transferred business assets. Moreover, if we, or substantially all of our assets, were acquired, or if we go out of business or enter bankruptcy, user information would be one of the assets that is transferred or acquired by a third party, and we would share <strong>personal data</strong> with the party that is acquiring our assets. You acknowledge that such transfers may occur, and that any acquirer of us or our assets may continue to use your <strong>personal information</strong> as set forth in this policy.</p>
              <p>How long do we retain your personal data?</p>
              <p>We retain <strong>personal data</strong> about you for as set forth on our data retention policy at <a href="https://billsby.com/terms">https://billsby.com/terms</a> in accordance with legal and additional compliance requirements. In some cases we retain <strong>personal data</strong> for longer, if doing so is necessary to comply with our legal obligations, resolve disputes or collect fees owed, or is otherwise permitted or required by applicable law, rule or regulation. Afterwards, we retain some information in a depersonalized or aggregated form but not in a way that would identify you personally.</p>
              <p>What security measures do we use?</p>
              <p>We seek to protect <strong>personal data</strong> using appropriate technical and organizational measures based on the type of <strong>personal data</strong> and applicable processing activity. For example, we use encryption and tokenization to keep your <strong>personal data</strong> secure.</p>
              <p>What rights do you have regarding your <strong>personal data</strong>?</p>
              <p>You have certain rights with respect to your <strong>personal data,</strong> including those set forth below. For more information about these rights, or to submit a request, please email {MailTo({classes: ''})}. Please note that in some circumstances, we may not be able to fully comply with your request, such as if it is frivolous or extremely impractical, if it jeopardizes the rights of others, or if it is not required by law, but in those circumstances, we will still respond to notify you of such a decision. In some cases, we may also need to you to provide us with additional information, which may include <strong>personal data,</strong> if necessary to verify your identity and the nature of your request.</p>
                <ul>
                  <li>Access: You can request more information about the <strong>personal data</strong> we hold about you and request a copy of such <strong>personal data</strong>.</li>
                  <li>Rectification: If you believe that any <strong>personal data</strong> we are holding about you is incorrect or incomplete, you can request that we correct or supplement such data.</li><li>Erasure: You can request that we erase some or all of your <strong>personal data</strong> from our systems.</li>
                  <li>Withdrawal of consent: If we are processing your <strong>personal data</strong> based on your consent (as indicated at the time of collection of such data), you have the right to withdraw your consent at any time. Please note, however, that if you exercise this right, you may have to then provide express consent on a case-by-case basis for the use or disclosure of certain of your <strong>personal data,</strong> if such use or disclosure is necessary to enable you to utilize some or all of our <strong>services</strong>.</li>
                  <li>Portability: You can ask for a copy of your <strong>personal</strong> <strong>data</strong> in a machine-readable format. You can also request that we transmit the data to another controller where technically feasible.</li>
                  <li>Objection: You can contact us to let us know that you object to the further use or disclosure of your <strong>personal data</strong> for certain purposes.</li>
                  <li>Restriction of processing: You can ask us to restrict further processing of your <strong>personal data</strong>.</li>
                  <li>Right to file complaint: You have the right to lodge a complaint about the <strong>company’s</strong> practices with respect to your <strong>personal data</strong> with the supervisory authority of your country or <strong>EU</strong> Member State.</li>
                </ul>
              <p>Transfers of personal data</p>
              <p>The <strong>services</strong> are hosted and operated in the United States (“U.S.”) through <strong>company</strong> and its service providers, and laws in the U.S. may differ from the laws where you reside. By using the <strong>services,</strong> you acknowledge that any <strong>personal data</strong> about you, regardless of whether provided by you or obtained from a third party, is being provided to <strong>company</strong> in the U.S. and will be hosted on U.S. servers, and you authorize <strong>company</strong> to transfer, store and process your information to and in the U.S., and possibly other countries. You hereby consent to the transfer of your data to the U.S. pursuant to EU-US Privacy Shield Framework and the Swiss-U.S. Privacy Shield Framework.</p><p>What if you have questions regarding your personal data?</p>
              <p>If you have any questions about this section or our data practices generally, please contact us using the following information:</p>
              <p><strong>Email: </strong>{MailTo({classes: ''})}</p>
        </div>
      </div>
    </Layout>
  )
}

export default Privacy